// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from "jquery";
window.$ = window.jQuery = $;
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "bootstrap";
import "../stylesheets/application";
require("admin-lte");
require("image_uploadify/imageuploadify.min");
import "@fortawesome/fontawesome-free/js/all";
import "../shared/cloudinary";
Rails.start();
Turbolinks.start();
ActiveStorage.start();

$(document).on("click", '[data-toggle="lightbox"]', function (event) {
  event.preventDefault();

  var $triggerElement = $(this);
  var createdAt = $triggerElement.data("created-at");
  console.log("createdAt", createdAt); // For debugging

  $triggerElement.ekkoLightbox({
    onShown: function () {
      // Initial update of created_at text
      var createdAt = $triggerElement.data("created-at");
      updateCreatedAtText(createdAt);
    },
    onNavigate: function (direction, itemIndex) {
      // Select the element with data-created-at-1 attribute
      const element = document.querySelector(
        "[data-created-at-" + itemIndex + "]"
      );

      if (element) {
        const createdAt = element.getAttribute("data-created-at-" + itemIndex);

        updateCreatedAtText(createdAt);
        // You can also perform other actions like adding event listeners
      } else {
        console.log("Element with data-created-at- not found.");
      }
    },
  });
});

function updateCreatedAtText(createdAt) {
  var $modalBody = $(".ekko-lightbox .modal-body");
  var createdAtText =
    '<h5 class="created-at">Created at: ' + createdAt + "</h5>";

  // Remove any existing created_at text and prepend the new one
  $modalBody.find(".created-at").remove();
  $modalBody.prepend(createdAtText);
}

document.addEventListener("turbolinks:load", () => {
  $(document).ready(function () {
    // Handler for changes in Priority checkboxes
    $(
      '#priorityDropdown + .dropdown-menu .dropdown-checkboxes input[type="checkbox"]'
    ).change(function () {
      var selectedPriorityOptions = [];
      $(
        '#priorityDropdown + .dropdown-menu .dropdown-checkboxes input[type="checkbox"]:checked'
      ).each(function () {
        selectedPriorityOptions.push($(this).val());
      });
      var priorityButtonText =
        selectedPriorityOptions.length > 0
          ? selectedPriorityOptions.join(", ")
          : "Select Priority";
      $("#priorityDropdown").text(priorityButtonText);
    });

    // Handler for changes in Source checkboxes
    $(
      '#sourceDropdown + .dropdown-menu .dropdown-checkboxes input[type="checkbox"]'
    ).change(function () {
      var selectedSourceOptions = [];
      $(
        '#sourceDropdown + .dropdown-menu .dropdown-checkboxes input[type="checkbox"]:checked'
      ).each(function () {
        selectedSourceOptions.push($(this).val());
      });
      var sourceButtonText =
        selectedSourceOptions.length > 0
          ? selectedSourceOptions.join(", ")
          : "Select Source";
      $("#sourceDropdown").text(sourceButtonText);
    });

    $(".overall_search").on("change", function (e) {
      $("#overall_search").submit();
    });
    $(".imageuploadify").imageuploadify();

    $("#propertyForm").on("submit", function () {
      var $submitButton = $(this).find('[type="submit"]');
      if ($submitButton.length) {
        $submitButton.attr("disabled", "disabled").val("Submitting...");
      }
    });

    $("#addPropertyImages").on("submit", function () {
      var $submitButton = $(this).find('[type="submit"]');
      if ($submitButton.length) {
        $submitButton.attr("disabled", "disabled").val("Uploading...");
      }
    });

    document.querySelectorAll(".downloadable").forEach(function (image) {
      image.addEventListener("click", function () {
        fetch(image.src)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // Use the file name from the image's src attribute, or specify your own
            const filename = image.src.split("/").pop();
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => alert("Download failed"));
      });
    });
  });
});
