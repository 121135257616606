import cloudinary from "cloudinary-core/cloudinary-core-shrinkwrap";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
// import a plugin
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';

document.addEventListener('DOMContentLoaded', function() {
  let cl = cloudinary.Cloudinary.new({ cloud_name: process.env.CLOUDINARY_CLOUD_NAME });
  cl.responsive();
});

document.addEventListener('lazybeforeunveil', function(e) {
  var bg = e.target.getAttribute('data-bg');
  if (bg) {
    e.target.style.backgroundImage = 'url(' + bg + ')';
  }
});
